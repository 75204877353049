import moment from 'moment';
import CONSTANTS from '../constants';
import UserLocationService from './UserLocationService';

export default {
  setLoginData,
  getToken,
  getTokenRefreshTime,
  removeLoginData,
  validateLogin,
  fbLogin,
  phoneLogin,
  transformFbDetails,
  updateProfile,
	isLoggedIn,
};

function setLoginData(tokenData) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, tokenData.token);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_userId`, tokenData.userId);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshToken`, tokenData.refreshToken);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshTime`, moment().unix());
	UserLocationService.syncWithServer();
}

function removeLoginData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_userId`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshToken`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshTime`);
}

function getToken() {
  let token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  if (token) return { token, userId: localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_userId`), refreshToken: localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshToken`) };
  return null;
}

function getTokenRefreshTime() {
  return localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshTime`);
}

async function validateLogin() {
  if(getToken() && getToken().token) {
    try {
      let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.CHECK}`, {
        method: 'POST',
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': getToken().token }
      });
      response = await response.json();
      if(!response || !response.valid) removeLoginData();
    } catch (e) {
      console.log(e);
      // removeLoginData();
    }
  }
}

function isLoggedIn() {
	const tokenInfo = getToken();
	const token = tokenInfo ? tokenInfo.token : null;
	if(!token) {
		return false;
	}
	return true;
}

async function fbLogin(fbDetails) {
  let data = transformFbDetails(fbDetails);
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN.FB}`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
  response = await response.json();
  if(response && response.data && response.data.token)
    return {
      type: response.data.type,
      userId: response.data.user_id,
      token: response.data.token,
      refreshToken: response.data.refresh_token,
      profileEmpty: response.data.profile_empty
    };
  else
    throw new Error(`Could not fetch token: ${response}`);
}

async function phoneLogin(details) {
  let data = { phone_number: details.phoneNumber };
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN.PHONE}`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
  response = await response.json();
  if(response && response.data && response.data.token)
    return {
      type: response.data.type,
      userId: response.data.user_id,
      token: response.data.token,
      refreshToken: response.data.refresh_token,
      profileEmpty: response.data.profile_empty
    };
  else
    throw new Error(`Could not fetch token: ${response}`);
}

function transformFbDetails(fbDetails) {
  return {
    first_name: fbDetails.name ? fbDetails.name.split(' ').slice(0, -1).join(' ') : '',
    last_name: fbDetails.name ? fbDetails.name.split(' ').slice(-1).join(' ') : '',
    screenname: fbDetails.name ? fbDetails.name.replace(/ /g,'') : '',
    facebook_email: fbDetails.email,
    email: fbDetails.email ? fbDetails.email : '',
    fb_id: fbDetails.id,
    password: CONSTANTS.LOGIN.VARIABLES.DEFAULT_PASSWORD,
    user_image: CONSTANTS.LOGIN.VARIABLES.DEFAULT_IMAGE,
    gender: fbDetails.gender ? fbDetails.gender : CONSTANTS.LOGIN.VARIABLES.GENDERS.MALE,
    relationship: fbDetails.relationship_status? fbDetails.relationship_status : CONSTANTS.LOGIN.VARIABLES.RELATIONSHIP_STATUS.UNKNOWN,
    date_of_birth: fbDetails.birthday ? fbDetails.birthday: '',
    fb_image: fbDetails.picture ? fbDetails.picture.data.url : '',
    phone_number: fbDetails.phoneNumber
  };
}

async function updateProfile(data, tokenData) {
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN.PROFILE}?access_token=${tokenData.token}`, {
    method: 'POST',
    body: JSON.stringify({ ...data, user_id: tokenData.userId })
  });

  response = await response.json();
  if(response && response.data) return response.data;
  throw new Error('Could not update profile');
}
