import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import reducers from './reducers';
import CONSTANTS from './constants';
import './index.scss';
import './App.scss';
import './assets/css/radio.scss';
import './assets/css/orderPref.scss';
import PrivateRoute from './components/shared/PrivateRoute';
import ScrollToTop from './components/shared/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import Spinner from './components/shared/Spinner';

const Login = lazy(() => import('./components/sessions/Login'))
const Logout = lazy(() => import('./components/sessions/Logout'))
const NearByLocations = lazy(() => import('./components/locations/NearByLocations'))
const ParentMenu = lazy(() => import('./components/locations/ParentMenu'))
const Menu = lazy(() => import('./components/locations/Menu'));
const Cart = lazy(() => import('./components/cart/Cart'));
const PaymentFlow = lazy(() => import('./components/orders/PaymentFlow'));
const CreateOrder = lazy(() => import('./components/orders/CreateOrder'));
const OrderStatus = lazy(() => import('./components/orders/Status'));
const AddReview = lazy(() => import('./components/reviews/AddReview'));
const NotificationsList = lazy(() => import('./components/shared/notifications/NotificationsList'));

const createStoreWithMiddleware = applyMiddleware(ReduxPromise, thunkMiddleware)(createStore);
ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <BrowserRouter>
      <ScrollToTop />
      <div className='app-container'>
        <Suspense fallback={<Spinner size={40} />} >
          <Switch>
            <Route exact path={`${CONSTANTS.BASE_URL}login`} component={Login} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}logout`} component={Logout} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}notifications`} component={NotificationsList} />
            <Route exact path={`${CONSTANTS.BASE_URL}`} component={NearByLocations} />
            <Route exact path={`${CONSTANTS.BASE_URL}master/:location_id`} component={ParentMenu} />
            <Route exact path={`${CONSTANTS.BASE_URL}:location_id/confirm/`} component={Cart} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}:location_id/orders/payments/flow`} component={PaymentFlow} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}:location_id/orders/create`} component={CreateOrder} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}:location_id/orders/status`} component={OrderStatus} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}:location_id/reviews/new`} component={AddReview} />
            <Route path={`${CONSTANTS.BASE_URL}:location_id`} component={(props) => <Menu {...props} />} />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
