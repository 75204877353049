import _ from 'lodash';

import LoginService from './LoginService';

import CONSTANTS from '../constants';

export default {
	getSavedLocations,
	setSavedLocations,
	updateSavedLocations,
	deleteSavedLocations,
	syncWithServer,
	checkIfSavedLocationExist,
	findLoc,
	setCheckForLocation,
	getCheckForLocation,
	setOpenPrefModal,
	getOpenPrefModal,
};

async function syncWithServer() {
	const tokenInfo = LoginService.getToken();
	const token = tokenInfo ? tokenInfo.token : null;
	if(!token) {
		return null;
	}

	let savedLocations = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`);
	savedLocations = savedLocations ? JSON.parse(savedLocations) : []

	const serverLocations = await getFromServer();
	const serverLocObj = {};
	for(let i=0; i<serverLocations.length; i++) {
		serverLocObj[serverLocations[i].id] = serverLocations[i];
	}

	for(let i=0; i<savedLocations.length; i++) {
		if(savedLocations[i].isDeleted) {
			if(!savedLocations[i].id || !serverLocObj[savedLocations[i].id]) {
				continue;
			}
			serverLocObj[savedLocations[i].id].isDeleted = true;
		} else {
			if(!savedLocations[i].id || !serverLocObj[savedLocations[i].id]) {
				serverLocations.push(savedLocations[i]);
				continue;
			}
			serverLocObj[savedLocations[i].id]['name'] = savedLocations[i].name;
			serverLocObj[savedLocations[i].id]['address'] = savedLocations[i].address;
			serverLocObj[savedLocations[i].id]['note'] = savedLocations[i].note;
			serverLocObj[savedLocations[i].id]['latitude'] = savedLocations[i].latitude;
			serverLocObj[savedLocations[i].id]['longitude'] = savedLocations[i].longitude;
		}
	}

	const fLocs = serverLocations.filter( sl => !sl.isDeleted );

	const syncData = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.USER_LOCATIONS.BULK_INSERT}`, {
		method: 'POST',
		body: JSON.stringify(fLocs),
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
	});

	if(syncData) {
		const locationsData = await syncData.json();
		localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`, JSON.stringify(locationsData));
	}
}

async function checkIfSavedLocationExist(location) {
	if(!location) {
		return false;
	}
	const locations = await getSavedLocations();
	for(let i=0; i<locations.length; i++) {
		if(`${locations[i].name}-${locations[i].latitude}-${locations[i].longitude}` === `${location.name}-${location.latitude}-${location.longitude}`) {
			return true;
		}
	}
	return false;
}

async function findLoc(location) {
	if(!location) {
		return null;
	}
	const locations = await getSavedLocations();
	for(let i=0; i<locations.length; i++) {
		if(`${locations[i].name}-${locations[i].latitude}-${locations[i].longitude}` === `${location.name}-${location.latitude}-${location.longitude}`) {
			return locations[i];
		}
	}
	return null;
}

async function getFromServer() {
	const tokenInfo = LoginService.getToken();
	const token = tokenInfo ? tokenInfo.token : null;
	if(!token) {
		return null;
	}
	const locationsRes = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.USER_LOCATIONS.DEFAULT}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
  });
	if(locationsRes) {
		const locationsData = await locationsRes.json();
		return locationsData;
	}
	return [];
}

async function setSavedLocations(state) {
	let savedLocations = await getSavedLocations(true);
	if( !savedLocations ) {
		savedLocations = [];
	}

	const location = {
		id: null,
		address: state.address,
		note: state.note,
		name: state.location_name,
		isSync: false,
		latitude: state.location.lat,
		longitude: state.location.lng,
		isDeleted: false,
	}
	savedLocations.push(location);
	localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`, JSON.stringify(savedLocations));
	syncWithServer();
	return location;
}

async function updateSavedLocations(state) {
	let data = await getSavedLocations(true);
	if( !data ) {
		data = [];
	}

	const savedLocations = data.filter( s => !s.isDeleted );

	savedLocations[state.itemIndex]["address"] = state.address;
	savedLocations[state.itemIndex]["note"] = state.note;
	savedLocations[state.itemIndex]["name"] = state.location_name;
	savedLocations[state.itemIndex]["isSync"] = false;
	savedLocations[state.itemIndex]["latitude"] = state.location.lat;
	savedLocations[state.itemIndex]["longitude"] = state.location.lng

	localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`, JSON.stringify(data));
	syncWithServer();
	return savedLocations[state.itemIndex];
}

async function deleteSavedLocations(index) {
	let savedLocations = await getSavedLocations(true);
	if( !savedLocations ) {
		savedLocations = [];
	}
	const data = savedLocations.filter( s => !s.isDeleted );
	data[index]["isDeleted"] = true;

	localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`, JSON.stringify(savedLocations));
	syncWithServer();
}

async function getSavedLocations(includeDeleted = false) {
	const locationData = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`);
	const data = locationData ? JSON.parse(locationData) : []
	if (data && data.length > 0) {
		return includeDeleted ? data : data.filter( s => !s.isDeleted );
	} else {
		const locations = await getFromServer();
		if(locations === null)
			return [];
		localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`, JSON.stringify(locations));
		return locations;
	}
}

function setCheckForLocation(flag){
	localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_check_location`,flag);
}
function getCheckForLocation(){
	return localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_check_location`);
}

function setOpenPrefModal(flag){
	localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_pref`,flag);
}
function getOpenPrefModal(){
	let data = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_pref`);
	if(data){
		return data;
	}
	return 'true'; 
}