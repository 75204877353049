import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LoginService from '../../services/LoginService';
import CONSTANTS from '../../constants';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={ props => (
    LoginService.getToken()
      ? <Component {...props} {...rest} />
      : <Redirect to={{ pathname: `${CONSTANTS.BASE_URL}login`, state: { from: props.location.pathname, data: props.location.state } }} />
    )}
  />
);

export default PrivateRoute;
