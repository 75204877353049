import { combineReducers } from 'redux';
import { error } from './alerts/ErrorReducer';
import { toast } from './alerts/ToastReducer';
import { location } from './locations/LocationReducer';
import { nearByLocations } from './locations/NearByLocationsReducer';
import { locationSettings } from './locations/LocationSettingsReducer';
import { childLocations } from './locations/ChildLocationsReducer';
import { categories } from './categories/CategoriesReducer';
import { items } from './items/ItemsReducer';
import { popularItems } from './items/PopularItemsReducer';
import { item, itemDetails } from './items/ItemReducer';
import { cart } from './cart/CartReducer';
import { processor } from './payments/ProcessorReducer';
import { statuses } from './configurations/StatusesReducer';
import { createdOrder, orders, orderTimes } from './orders/OrderReducer';
import { notifications } from './notifications/NotificationsReducer';
import { promotions } from './promotions/PromotionsReducer';

export default combineReducers({
  toast,
  error,
  location,
  categories,
  items,
  itemDetails,
  popularItems,
  item,
  cart,
  createdOrder,
  orders,
  orderTimes,
  nearByLocations,
  childLocations,
  processor,
  statuses,
  promotions,
  notifications,
  locationSettings
});
