import React, { Component } from 'react';
import { GridLoader } from 'react-spinners';

import '../../assets/css/spinner.scss';

export default class Spinner extends Component {
  render (){
    return (
      <div className='spinner'>
        <div>
          <GridLoader {...this.props} color='var(--primary-color)' loading={true} />
        </div>
      </div>
    );
  }
}
